import "./ContainerTitle.css";
import { Component } from "react";

class ContainerTitle extends Component {
  render() {
    return <span className="container-title">{this.props.children}</span>;
  }
}

export default ContainerTitle;
