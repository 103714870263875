import "./Container.css";
import { Component } from "react";

class Container extends Component {
  render() {
    const { image_url, bg_color, height, display, flexDirection, alignItems, justifyContent, padding } = this.props;

    const containerStyle = {
      backgroundImage: image_url ? `url(${image_url})` : 'none',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: image_url ? 'transparent' : bg_color || '#0000',
      height: height ? height : '70vh',
      display: display ? display : 'flex',
      flexDirection: flexDirection ? flexDirection : 'column',
      alignItems: alignItems ? alignItems : 'flex-start',
      justifyContent: justifyContent ? justifyContent : 'center',
      padding: padding ? padding : '0'
    };

    return (
      <div className="container" style={containerStyle}>
        {this.props.children}
      </div>
    );
  }
}

export default Container;
