import "./NavbarItems.css";
import "./NavbarItem/NavbarItem";
import { Component} from "react";
import NavbarItem from "./NavbarItem/NavbarItem";
import { items } from "../../Configurations/Configs";

class NavbarItems extends Component {
  render() {
    return (
      <div className="navbar-items">
        {Object.entries(items).map(([label, link], index) => (
          <NavbarItem key={index} link={link}>
            {label}
          </NavbarItem>
      ))}
      </div>
    );
  }
}

export default NavbarItems;
