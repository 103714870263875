import {Component} from 'react';
import './SocialMediaIcons.css'
import SocialMediaIcon from './SocialMediaIcon/SocialMediaIcon';
import { social_media_icons } from '../../../Configurations/Configs';

class SocialMediaIcons extends Component
{
    render()
    {
        return (
            <div className='social-media-icons'>
                 {Object.entries(social_media_icons).map(([platform, url], index) => (
                    <SocialMediaIcon key={index} link={url}>{platform}</SocialMediaIcon>
                ))}
            </div>
        );
    }
}

export default SocialMediaIcons;