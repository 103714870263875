import "./NavbarItem.css";
import { Component } from "react";

class NavbarItem extends Component {
  render() {
    return (
      <a href={this.props.link} className="navbar-item chalk-underline">
        {this.props.children}
      </a>
    );
  }
}

export default NavbarItem;
