import Logo from "./Logo/Logo";
import "./Navbar.css";
import { Component } from "react";
import NavbarItems from "./NavbarItems/NavbarItems";
import SocialMediaIcons from "./NavbarItems/SocialMediaIcons/SocialMediaIcons";
import {Divide as Hamburger} from "hamburger-react";

class Navbar extends Component {
  render() {
    return (
      <div className="navbar">
        <div className="navbar-container">
          <Logo />
          <NavbarItems />
          <SocialMediaIcons/>
          <Hamburger color="#f7d47e"/>
        </div>
      </div>
    );
  }
}

export default Navbar;
