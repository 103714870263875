import { Component } from "react";
import './SocialMediaIcon.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faLinkedin, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

class SocialMediaIcon extends Component
{
    render()
    {
        let icon = faFacebookSquare;
        switch(this.props.children)
        {
            case 'facebook':
                icon = faFacebookSquare;
                break;
            case 'linkedin':
                icon = faLinkedin;
                break;
            case 'instagram':
                icon = faInstagram;
                break;
            case 'youtube':
                icon = faYoutube;
                break;
        }
        return (
            <a href={this.props.link} target="_blank" rel="">
                <FontAwesomeIcon className="social-media-icon" icon={icon}/>
            </a>
        );
    }
}

export default SocialMediaIcon;