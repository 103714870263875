import { Component } from "react";
import InputBox from "./InputBox/InputBox";
import "./ContactUsForm.css";
import Button from "../Button/Button";

class ContactUsForm extends Component {
  render() {
    return (
      <form className="contact-us-form" action="submit">
        <InputBox>First Name</InputBox>
        <InputBox>Last Name</InputBox>
        <InputBox>Email</InputBox>
        <Button value="#">Contact Us</Button>
      </form>
    );
  }
}

export default ContactUsForm;
