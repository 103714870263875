import { Component, Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home/Home";
import AboutUs from "../Pages/AboutUs/AboutUs";
import PressKit from "../Pages/PressKit/PressKit";

class Main extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/press-kit" element={<PressKit />} />
        </Routes>
      </Router>
    );
  }
}

export default Main;
