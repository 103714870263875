import Container from "../../Components/Container/Container";
import TeamMember from "../../Components/TeamMember/TeamMember";
import "./AboutUs.css";
const { Component } = require("react");

class AboutUs extends Component {
  render() {
    return (
      <div className="about-us">
        <Container
          image_url="https://placehold.co/1280x720/424242/424242"
          height="fit-content"
          padding="150px 0 100px 0"
          justifyContent="space-around"
        >
          <div className="about-us-details">
            <h1 className="about-us-title">About Us</h1>
            <p className="about-us-description">
              Welcome to Avyaan Studios, a vibrant indie game development studio
              headquartered in the historic city of Moradabad, India. Our
              passion lies in crafting immersive gaming experiences inspired by
              the timeless tales and rich heritage of Hindu mythology.
            </p>
            <p className="about-us-description">
              At Avyaan Studios, we blend storytelling with engaging gameplay,
              creating worlds that celebrate India's cultural legacy. We are
              currently working on an ambitious 2D Metroidvania game, bringing
              ancient legends to life in a way never seen before.
            </p>
            <p className="about-us-description">
              As an indie studio, our vision is to push the boundaries of
              creativity and innovation, delivering games that resonate with
              players worldwide. Stay tuned for updates as we embark on this
              exciting journey of myth and adventure!
            </p>
          </div>
        </Container>

        <Container
          height="fit-content"
          bg_color="#2a232b"
          justifyContent="flex-start"
          padding="70px 0"
        >
          <div className="our-team">
            <h1 className="team-title">Our Team</h1>
            <TeamMember
              memberImage="https://avatar.iran.liara.run/public/43"
              name="Jai"
              description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            />
            <TeamMember
              memberImage="https://avatar.iran.liara.run/public/74"
              name="Jhalak"
              description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            />
          </div>
        </Container>
      </div>
    );
  }
}

export default AboutUs;
