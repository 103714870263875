export let social_media_icons = {
  facebook: "https://www.facebook.com",
  linkedin: "https://www.linkedin.com/company/99292649/",
  instagram: "https://www.instagram.com/avyaanstudios/?hl=en",
  youtube: "https://www.youtube.com/@AvyaanStudios",
};

export let items = {
  Home: "/",
  "Wishlist on Steam": "#",
  "About Us": "/about-us",
  "Press Kit": "/press-kit",
};
