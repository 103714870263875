import "./Logo.css";
import { Component, Fragment } from "react";
import logo from "../../res/avyaan_logo.png";

class Logo extends Component {
  render() {
    return (
      <div className="logo">
        <img className="avyaan-logo" src={logo} />
        <span className="logo-title">
          <span className="logo-initial">Avyaan</span>
          <span>Studios</span>
        </span>
      </div>
    );
  }
}

export default Logo;
