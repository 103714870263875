import "./Home.css";
import { Component } from "react";
import Container from "../../Components/Container/Container";
import ContainerTitle from "../../Components/Container/ContainerTitle/ContainerTitle";
import Title from "../../Components/Title/Title";
import ContactUsForm from "../../Components/ContactUsForm/ContactUsForm";

class Home extends Component {
  render() {
    return (
      <div className="home">
        <Container image_url="https://placehold.co/1280x720/424242/424242">
          <ContainerTitle>Bringing Indian Mythology to life</ContainerTitle>
        </Container>
        <Container
          height="fit-content"
          bg_color="#2a232b"
          justifyContent="flex-start"
        >
          <Title>Contact Us</Title>
          <ContactUsForm />
        </Container>
      </div>
    );
  }
}

export default Home;
