import { Component } from "react";
import "./InputBox.css"

class InputBox extends Component
{
    render()
    {
        return(
            <div className="input-box-container">
                <label className="input-box-label">{this.props.children}</label>
                <input className="input-box"/>
                </div>
        );
    }
}

export default InputBox;