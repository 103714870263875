import "./PressKit.css";
const { Component } = require("react");

class PressKit extends Component {
  render() {
    return <div className="press-kit"></div>;
  }
}

export default PressKit;
